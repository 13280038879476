.root {
  user-select: none;
  width: 1em;
  height: 1em;
  display: inline-block;
  fill: currentColor;
  flex-shrink: 0;
  font-size: 24px;
  font-weight: bold;
  transition: fill 0.2s;
}
