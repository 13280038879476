@import "../../theme/colours";

.container {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 18px;
}

.input {
  position: absolute;
  cursor: pointer;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  min-height: 100%;
  min-width: 100%;
  z-index: 1;
  margin: 0;
  zoom: 1;
  filter: alpha(opacity=0);
  opacity: 0;
}

.restingIcon {
  width: 18px;
  height: 18px;
  opacity: 1;
  display: block;
  transition: opacity ease .2s;
  .input:checked ~ & {
    opacity: 0;
  }

  :focus ~ & {
    outline: 2px solid orange;
  }
}

.checkedIcon {
  width: 18px;
  height: 18px;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  transition: opacity ease .2s;
  .input:checked ~ & {
    opacity: 1;
  }

  :focus ~ & {
    outline: 2px solid orange;
  }
}
