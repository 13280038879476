$color-PrimaryDark2: #002a4d;
$color-PrimaryDark1: #005599;
$color-Primary: #0072ce;
$color-PrimaryLight1: #178ae6;
$color-PrimaryLight2: #b3ddff;
$color-PrimaryLight3: #eff8ff;
$color-NGDark2: #12151a;
$color-NGDark1: #3d444d;
$color-NG-NeutralsGrey: #5c6066;
$color-NGLight1: #b8c0cc;
$color-NGLight2: #edeff2;
$color-NGLight3: #fafafa;
$color-SuccessDark: #548000;
$color-Success: #87b336;
$color-SuccessLight: #daf2aa;
$color-ErrorDark: #a61b2f;
$color-Error: #d93049;
$color-ErrorLight: #f2667b;
$color-WarningDark: #cc8e00;
$color-Warning: #f2a900;
$color-WarningLight: #ffd980;
$color-White: #ffffff;
