@import '../../../../theme/colours';

.dialog {
  width: 600px;
}

.form {
  margin-top: 32px;
}

.dialogAction {
  display: flex;
  padding: 16px 0;
  justify-content: flex-end;
}

.text {
  color: $color-NGDark1;
  overflow: auto;
  max-height: 50vh;
  display: block;
}

.cancelButton {
  margin-right: 16px;
}

.updateProgressText {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1 1 auto;
  color: $color-NGDark1;
}

.dialogContent {
  display: flex;
  flex-direction: column;
}

.userName {
  font-family: sans-serif;
  font-size: 15px;
}
