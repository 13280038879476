@import "../../theme/colours";

$space-xss: 4px;
$space-xs: 8px;
$space-s: 12px;
$space-m: 16px;
$space-l: 24px;
$border-radius: 6px;
$border-size: 2px;

.root {
  border-radius: $border-radius;
  display: inline-flex;
  align-items: center;
  vertical-align: middle;
  box-sizing: border-box;
  cursor: pointer;
  user-select: none;
  border: solid $border-size transparent;
  margin: 0; // Remove the margin in Safari
  text-decoration: none;

  &:focus {
    outline: solid $border-size orange;
  }

  &:disabled,
  &.disabled {
    cursor: default;
    outline: none;
  }

  &[data-item="menuitem"] {
    width: 100%;
    outline: none;
  }

  -webkit-tap-highlight-color: transparent;
  -moz-appearance: none; // Reset
  -webkit-appearance: none; // Reset

  &::-moz-focus-inner {
    border-style: none; // Remove Firefox dotted outline.
  }
}

.icon {
  line-height: 0;

  >*:first-child {
    font-size: 18px;
    vertical-align: middle;
  }
}

.start {
  margin-left: -4px;
  margin-right: 2px;
}

.end {
  margin-right: -4px;
  margin-left: 2px;
}

.alone {
  margin: 0 -4px;
}

.label {
  width: 100%; // Ensure the correct width for iOS Safari
  display: inherit;
  align-items: inherit;
  justify-content: inherit;
  text-align: inherit;
  color: inherit;
}

.small {
  padding: $space-xss - $border-size $space-xs - $border-size;
}

.medium {
  padding: $space-s - $border-size $space-m - $border-size;
}

.large {
  padding: $space-m - $border-size $space-l - $border-size;
}

.contained {
  background: $color-Primary;
  color: $color-White;

  &[data-item="menuitem"]:focus,
  &:hover {
    background: $color-PrimaryDark1;
  }

  &:active,
  &.active {
    background: $color-PrimaryDark1;
    color: $color-PrimaryLight2;
  }

  &:disabled,
  &.disabled {
    color: $color-NGLight1;
    background: $color-NGLight2;
  }
}

%action-colors {
  color: $color-Primary;

  &[data-item="menuitem"]:focus,
  &:hover {
    color: $color-PrimaryDark1;
  }

  &:active,
  &.active {
    color: $color-PrimaryDark1;
    background: $color-PrimaryLight2;
  }

  &:disabled,
  &.disabled {
    color: $color-NGLight1;
    background: transparent;
  }
}

.outlined {
  @extend %action-colors;
  background: $color-White;
  border-color: currentColor;
}

.text {
  @extend %action-colors;
  background: transparent;
  border: solid $border-size transparent;

  &[data-item="menuitem"]:focus,
  &:hover {
    background: $color-NGLight2;
  }

  &:active,
  &.active {
    background: $color-PrimaryLight2;
  }

  &:disabled,
  &.disabled {
    background: transparent;
  }
}

.buttonDisabled {

  &:disabled,
  &.disabled {
    background-color: $color-NGLight2;
  }
}
