@import './../../theme/colours';

.wrapper {
  position: fixed;
  background-color: $color-NGDark2;
  min-height: 68px;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 2vw 0 4vw;
  z-index: 3;
}

.actionsWrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex: 1;
}

.content {
  flex: 3;
  color: $color-White;
  padding: 12px 0 16px;
}
