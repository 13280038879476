@import '../../theme/colours';

.wrapper {
  display: flex;
  height: 10vh;
  width: 25vw;
  align-items: center;
  justify-content: center;

  & div {
    border-radius: 20px;
    background-color: $color-PrimaryLight1;
    width: 3px;
    margin: 2px;
  }
}

@media screen and (max-width: 800px) {
  .wrapper {
    width: 100%;
  }
}

.line1 {
  height: 5.9px;
}

.line2 {
  height: 25.6px;
}

.line3 {
  height: 19.2px;
}

.line4 {
  height: 46.4px;
}

.line5 {
  height: 8.6px;
}

.line6 {
  height: 75.7px;
}

.line7 {
  height: 65.3px;
}

.line8 {
  height: 30.6px;
}

.line9 {
  height: 20.7px;
}

.line10 {
  height: 10.4px;
}

.line11 {
  height: 31.3px;
}

.line12 {
  height: 42.4px;
}

.line13 {
  height: 3px;
}


  