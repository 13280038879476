@import '../theme/colours';

.dialog {
  color: $color-NGDark1; 
  max-width: 47%;
}
.dialogContent { 
  box-sizing: border-box;
  padding: 32px 16px 16px; 
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.dialogActions {
  display: flex;
  justify-content: flex-end;
}

.confirmationButton {
  display: inline-block;
  text-align: center;
} 

.overlay {
  z-index: 1300;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  position: fixed;
  background-color: transparent;
  opacity: 0;
}

.hourGlass {
  color: $color-Primary;
  line-height: 0;
  padding: 16px 18px 0;
}

.notificationMessage {
  margin-bottom: 16px;
  display: inline-block;
}
