@import "../../theme/colours";

.container {
  position: relative;
  display: inline-flex;
  background: $color-White;
  border: solid 1px $color-NGLight2;
  border-radius: 6px;
  transition: all 0.3s ease;
  color: $color-NG-NeutralsGrey;
  width: 100%;
  outline: none;

  &:hover {
    box-shadow: 0 3px 15px 0 rgba(0, 0, 0, 0.05);
  }

  &.focused {
    box-shadow: 0 3px 15px 0 rgba(0, 0, 0, 0.05);
    border-color: $color-NGLight1;
  }
}

%container-icon {
  color: $color-Primary;
  padding: 10px;
  font-size: 10px;

  .icon {
    display: block;
    font-size: 20px;
  }
}

.decor {
  @extend %container-icon;
  background: $color-PrimaryLight3;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  margin-right: 6px;
}

.clear {
  margin: 7px;
  color: $color-NGDark1;

  &:hover {
    color: $color-NGDark1;
  }
}

.contents {
  padding: 6px;
  width: 100%;
  display: flex;
  align-items: center;
}

.barLabel {
  white-space: nowrap;
  display: inline-block;
  position: relative;
  vertical-align: sub;

  &:focus {
    outline: none;
  }
}

.barLabelDate {
  color: $color-Primary;
}

.dialog {
  position: absolute;
  top: 100%;
  right: 0;
  margin-top: 4px;
  border-radius: 6px;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.05);
  border: solid 1px $color-NGLight1;
  background-color: $color-White;
  padding: 1px 8px;
  list-style: none;
  box-sizing: border-box;
  text-align: left;
  overflow: auto;
  padding: 0 24px 24px 24px;
  z-index: 1301;
}

.screenReader {
  clip: rect(1px, 1px, 1px, 1px);
  font-size: 14px;
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

.hidden {
  display: none;
}

.visuallyhidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.tabList {
  border-bottom: $color-NGLight2 2px solid;
  height: 50px;
  display: flex;
  align-items: flex-end;
  width: 100%;
}

.tabButton {
  border-left: none;
  border-right: none;
  border-bottom: $color-Primary 2px solid;

  &:hover {
    border-bottom: $color-PrimaryDark1 2px solid;
  }

  border-radius: 0;
  margin-bottom: -2px;
  margin-right: 24px;
  padding: 14px 0;
}

.inactiveTab {
  border-bottom: $color-NGLight2 2px solid;
  color: $color-NGDark1;
}

.cancelButton {
  margin-right: 8px;
}

.dialogActions {
  margin-top: 16px;
  display: flex;
  justify-content: flex-end;
}

.overlay {
  z-index: 1300;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  position: fixed;
  background-color: transparent;
  opacity: 0;
}

.input {
  border: 0;
  margin: 0;
  width: 0;
  padding: 6px 0 7px;
  background: none;
  box-sizing: content-box;
  letter-spacing: inherit;
  -webkit-tap-highlight-color: transparent;

  &:focus {
    outline: none;
  }

  display: inline-flex;
  align-items: center;
}

.wrapper {
  display: inline-flex;
  align-items: center;
  outline: none;
}
