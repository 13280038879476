@import "../../theme/colours";

.tabList {
  min-height: 72px;
  display: flex;
  align-items: flex-end;
  z-index: 1;
}

.tabButton {
  border-left: none;
  border-right: none;
  border-bottom: $color-Primary 3px solid;

  &:hover {
    border-bottom: $color-PrimaryDark1 3px solid;
  }

  min-height: 72px;
  border-radius: 0;
  margin-bottom: -2px;
  margin-right: 24px;
  padding: 14px 0;
  text-align: center;
}

.inactiveTab {
  border-bottom: $color-NGLight2 3px solid;
  color: $color-NGDark1;
}

.hidden {
  display: none;
}
