@import "../../theme/colours";
@import '../../theme/sizes';

.decor {
  background: $color-PrimaryLight3;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  margin-right: 6px;
}

.empty {
  margin: 28px 4px;
  text-align: center;
  color: $color-NG-NeutralsGrey;
}

.inputBox {
  display: inline-flex;
  background: $color-White;
  height: 100%;
  transition: all 0.3s ease;
  width: 100%;
  align-items: center;
  border-radius: 6px;
  padding-left: 18px;
  box-sizing: border-box;
}

.input {
  width: 100%;
  border: 0;
  padding: 6px 0 7px;
  min-width: 0;
  background: none;
  box-sizing: content-box;
  letter-spacing: inherit;
  color: $color-Primary;
  -webkit-tap-highlight-color: transparent;
  &:focus {
    outline: none;
  }
}

.inputLabel {
  display: inline-block;
  width: 0;
  height: 0;
  visibility: hidden;
}

.placeholder {
  white-space: nowrap;
  display: inline-block;
  position: absolute;
  color: $color-NGDark1;
}

.suggestion {
  color: $color-NGDark1;
  padding: 2px 12px;
  display: flex;
  justify-content: space-between;
  &:hover, &:focus, &:active {
    color: $color-PrimaryDark1;
  }
}

.selectedCenter {
  color: $color-PrimaryDark1;
}

.doneIcon {
  font-size: $size-xxsmall;
}

.dropdownBox {
  max-height: 200px;
  overflow-y: auto;
}

.clear {
  margin: 7px;
  color: $color-NGDark1;

  &[data-item="menuitem"] {
    width: inherit;

    &:focus {
      outline: solid 2px orange;
      background-color: transparent;
      color: $color-NGDark1;
    }
    
    &:hover {
      background: $color-NGLight2;
      color: $color-NGDark1;
    }

    &:active {
      background: $color-PrimaryLight2;
    }
  }
}

.hidden {
  display: none;
}

