@import '../../theme/colours';

.eulaButton {
  margin-top: 32px;
}

.eulaText {
  overflow-y: auto;
  height: 100%;
  color: $color-NG-NeutralsGrey;

}

.eulaActionContainer {
  margin-top: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.eulaContainer {
  width: 1000px;
  background-color: $color-White;
  border-radius: 6px;
  padding: 32px 31px;
  height: 65vh;
  align-self: center;
}

@media only screen and (max-width: 1100px) {
  .eulaContainer{
    width: 90%;
  }
}
