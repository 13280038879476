@import '../../theme/colours';

.overlay {
  z-index: 1500;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-tap-highlight-color: transparent;
  opacity: 1;
  transition: opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  outline: 0;
}

.container {
  max-width: 960px;
  width: calc(100% - 72px);
  display: flex;
  max-height: calc(100% - 72px);
  flex-direction: column;
  margin: 32px;
  position: relative;
  overflow-y: auto;
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14),
    0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  color: rgba(0, 0, 0, 0.87);
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  background-color: #fff;
}

.content {
  min-height: 150px;
  flex: 1 1 auto;
  padding: 8px 24px;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

.title {
  margin: 0;
  padding: 24px 24px;
  background-color: $color-NGLight3;
  flex: 0 0 auto;
}

.closeButton {
  position: absolute;
  right: 10px;
  top: 10px;
  color: $color-NGDark2;
}
