@import "./sizes";
@import "./fonts";
@import "./colours";

.HeadingXLBold {
  font-size: $size-xxxlarge;
  font-family: $font-family-source-sans-pro;
  font-weight: 700;
  line-height: 1.17;
};

.HeadingXLRegular {
  font-size: $size-xxxlarge;
  font-family: $font-family-source-sans-pro;
  font-weight: 400;
  line-height: 1.17;
};

.HeadingLBold {
  font-size: $size-xxlarge;
  font-family: $font-family-source-sans-pro;
  font-weight: 700;
  line-height: 1.22;
};

.HeadingLRegular {
  font-size: $size-xxlarge;
  font-family: $font-family-source-sans-pro;
  font-weight: 400;
  line-height: 1.22;
};

.HeadingMBold {
  font-size: $size-xlarge;
  font-family: $font-family-source-sans-pro;
  font-weight: 700;
  line-height: 1.17;
};

.HeadingMRegular {
  font-size: $size-xlarge;
  font-family: $font-family-source-sans-pro;
  font-weight: 400;
  line-height: 1.17;
};

.HeadingSBold {
  font-size: $size-large;
  font-family: $font-family-source-sans-pro;
  font-weight: 700;
  line-height: 1.2;
};

.HeadingSRegular {
  font-size: $size-large;
  font-family: $font-family-source-sans-pro;
  font-weight: 400;
  line-height: 1.2;
};

.SubheadingBold {
  font-size: $size-medium;
  font-family: $font-family-source-sans-pro;
  font-weight: 700;
  line-height: 1.33;
};

.SubheadingRegular {
  font-size: $size-medium;
  font-family: $font-family-source-sans-pro;
  font-weight: 400;
  line-height: 1.33;
};

.BodyBold {
  font-size: $size-small;
  font-family: $font-family-source-sans-pro;
  font-weight: 700;
  line-height: 1.5;
}

.BodyRegular {
  font-size: $size-small;
  font-family: $font-family-source-sans-pro;
  font-weight: 400;
  line-height: 1.5;
}

.TextMBold {
  font-size: $size-small;
  font-family: $font-family-source-sans-pro;
  font-weight: 700;
  line-height: 1.25;
}

.TextMRegular {
  font-size: $size-small;
  font-family: $font-family-source-sans-pro;
  font-weight: 400;
  line-height: 1.25;
}

.TextMRegularGrey {
  font-size: $size-small;
  font-family: $font-family-source-sans-pro;
  color: $color-NG-NeutralsGrey;
  font-weight: 400;
  line-height: 1.25;
}

.TextSBold {
  font-size: $size-xsmall;
  font-family: $font-family-source-sans-pro;
  font-weight: 700;
  line-height: 1.14;
}

.TextSRegular {
  font-size: $size-xsmall;
  font-family: $font-family-source-sans-pro;
  font-weight: 400;
  line-height: 1.14;
}

.TextXSBold {
  font-size: $size-xxsmall;
  font-family: $font-family-source-sans-pro;
  font-weight: 700;
  line-height: 1.33;
}

.TextXSRegular {
  font-size: $size-xxsmall;
  font-family: $font-family-source-sans-pro;
  font-weight: 400;
  line-height: 1.33;
}
