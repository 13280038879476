@import "../../theme/colours";
@import "../../theme/sizes";

.container {
  border-bottom: $color-NGLight2 1px solid;
  display: inline-block;
  padding: 25px 0 12px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
}

.table {
  text-align: center;
  border-collapse: collapse;
  border-top: $color-NGLight2 1px solid;
}

.hcell {
  padding: 24px 0 12px;
}

.cell {
  color: #000000;
  padding: 0;
  width: 28px;
  height: 28px;

  &:focus {
    outline: none;

    .cellbox {
      outline: solid 2px orange;
    }
  }

  &.disabledCell {
    color: $color-NGLight1;
    background: $color-NGLight2;

    &:focus {
      outline: none;

      .cellbox {
        outline: none;
      }
    }
  }
}

.cellbg {
  display: block;
  margin: 6px 0;
  padding: 0 6px;
}

.cellbox {
  width: 28px;
  height: 28px;
  display: block;
  line-height: 28px;
  border-radius: 6px;
}

.selectMiddle {
  background-color: $color-PrimaryLight2;
}

.selectStart {
  margin-left: 6px;
  padding-left: 0;
  background-color: $color-PrimaryLight2;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}

.selectEnd {
  margin-right: 6px;
  padding-right: 0;
  background-color: $color-PrimaryLight2;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}

.marked {
  color: $color-White;
  background-color: $color-Primary;
}

.group {
  display: flex;

  .container {
    margin-left: 61px;

    &:first-child {
      margin-left: 0;
    }
  }
}

.selectContainer {
  display: flex;
  justify-content: space-between;
}

.start {
  margin-right: 90px;
  margin-left: 0px;
}

.end {
  margin-right: 10px;
  margin-left: 90px;
}

.selectArrowRedesign {
  -moz-appearance: none;
  -webkit-appearance: none;
  background: url('../../public/DownArrow.svg') no-repeat;
  padding: 2px 21px 6px 0px;
}

.selectMonth {
  background-position: right 4px top 50%;
  padding-right: 25px;
}

.selectYear {
  background-position: right 2px top 50%;
  padding-left: 10px;
}

.dropdownBox {
  max-height: 350px;
  width: 70px;
  overflow-y: auto;
}

.dropdownLabel {
  color: $color-Primary;
}

.select { 
  box-shadow: none;
  cursor: pointer;
  min-width: 16px;
  user-select: none;
  border-radius: 0;
  color: $color-Primary;
  width: 100%;
  border: 0;
  margin: 0;
  display: block;
  min-width: 0;
  box-sizing: content-box;
  letter-spacing: inherit;

  -webkit-tap-highlight-color: transparent;
}

.suggestionBase {
  color: $color-NGDark1;
  padding: 2px 6px;
  display: flex;
  justify-content: space-between;
}

.activeSuggestion {
  @extend .suggestionBase;
  &:hover, &:focus, &:active {
    color: $color-PrimaryDark1;
  }
}

.selectedItem {
  color: $color-PrimaryDark1;
}

.doneIcon {
  font-size: $size-xxsmall;
}
