@import '../../../theme/colours';

$top: 150px;
$title: 40px;
$actions: 60px;
$tabButton: 50px;
$input: 50px;
$tabsContainerReduction: $top + $title + $actions;
$tabPanelReduction: $tabsContainerReduction + $tabButton + $input;

.tabLabel {
  padding: 0 16px;
}

.tabButton {
  width: 50%;
  margin: 0;
  min-height: $tabButton;
  padding: 7px 0;
  display: flex;
  justify-content: center;
  border: none;
  z-index: 2;

  &:hover {
    border-bottom: $color-PrimaryDark1 3px solid;
    padding-bottom: 4px;
  }
}

.tabActive {
  border-bottom: $color-Primary 3px solid;
  padding-bottom: 4px;
}

.tabList {
  width: 100%;
  min-height: $tabButton;

}

.sidebarActions {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: $actions;
  z-index: 2;
  background-color: $color-White;
}

.sidebarTitle {
  color: $color-NG-NeutralsGrey;
  height: $title;
  box-sizing: border-box;
}

.saveButton {
  margin: 0 12px 0 16px;
}

.tabPanel {
  display: flex;
  flex-direction: column;
  padding: 16px 16px 0;
  overflow-y: auto;
  max-height: calc(100vh - #{$tabPanelReduction} - 2px);
  box-sizing: border-box;
}

.tabsContainer {
  flex: 1;
  max-height: calc(100vh - #{$tabsContainerReduction} - 2px);
}

.supervisor {
  cursor: pointer;
  margin-bottom: 16px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex: 1 0 auto;
}

.supervisorName {
  margin-left: 10px;
}

.sidebarContainer {
  top: $top;
  margin-right: 2px;
  max-height: calc(100vh - #{$top});
}

.sidebarSeparator {
  margin-top: 0px;
  padding-top: 0px;
}

.sidebarInput {
  height: $input;
  box-sizing: border-box;
}
