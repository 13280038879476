@import "../../theme/colours";

.container {
  position: relative;
  display: inline-flex;
  background: $color-White;
  border: solid 1px $color-NGLight2;
  border-radius: 6px;
  transition: all 0.3s ease;

  &:hover {
    box-shadow: 0 3px 15px 0 rgba(0, 0, 0, 0.05);
  }

  &.focused {
    box-shadow: 0 3px 15px 0 rgba(0, 0, 0, 0.05);
    border-color: $color-NGLight1;
  }
}

%container-icon {
  color: $color-Primary;
  padding: 10px;
  font-size: 10px;
  .icon {
    display: block;
    font-size: 20px;
  }
}

.decor {
  @extend %container-icon;
  background: $color-PrimaryLight3;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  margin-right: 6px;
}

.clear {
  margin: 7px;
  color: $color-NGDark1;
  &:hover {
    color: $color-NGDark1;
  }
}

.contents {
  padding: 6px;
  width: 100%;
}


.scrollableContents::-webkit-scrollbar {
  display: none;
}

.empty {
  margin: 28px 4px;
  color: $color-NG-NeutralsGrey;
}

.inputBox {
  display: inline-flex;
  align-items: center;
}

.input {
  width: 100%;
  border: 0;
  margin: 0;
  padding: 6px 0 7px;
  min-width: 0;
  background: none;
  box-sizing: content-box;
  letter-spacing: inherit;
  -webkit-tap-highlight-color: transparent;
  &:focus {
    outline: none;
  }
}

.inputLabel {
  white-space: nowrap;
  display: inline-block;
  position: absolute;
  color: $color-Primary;
  &.focused {
    color: $color-NG-NeutralsGrey;
  }
}

.hiddenLabel {
  @extend .inputLabel;
  left: -999em;
}

.group {
  padding: 0;
  margin: 0;
  list-style: none;
}

.groupLabel {
  white-space: nowrap;
  color: $color-NG-NeutralsGrey;

  .selectionGroup & {
    margin-right: 4px;
  }

  .suggestionGroup & {
    margin-top: 8px;
    margin-bottom: 8px;
  }
}

.option {
  display: inline-block;
  cursor: pointer;
  border-radius: 6px;
  padding: 4px;
  color: $color-PrimaryDark1;
  background: $color-NGLight2;
  font-size: 12px;
  font-family: -apple-system, BlinkMacSystemFont,
    sans-serif;

  .selectionGroup & {
    margin: 2px 4px 2px 0;
    color: $color-PrimaryDark1;
    &:after {
      content: " \00d7";
    }
  }

  .suggestionGroup & {
    margin-right: 12px;
    margin-bottom: 8px;
  }
  &.focused {
    outline: solid 2px orange;
  }
}

.selectionListBox {
  display: inline;
}

.selectionGroup {
  display: inline;
  .groupLabel {
    display: inline;
  }
}

.suggestionGroup {
  display: inline;
}

.suggestionListBox {
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 0;
  margin-top: 4px;
  min-width: 100%;
  border-radius: 6px;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.05);
  border: solid 1px $color-NGLight1;
  background-color: $color-White;
  padding: 1px 8px;
  list-style: none;
  box-sizing: border-box;
  text-align: left;
  max-height: 300px;
  overflow: auto;
}

.screenReader {
  clip: rect(1px, 1px, 1px, 1px);
  font-size: 14px;
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

.hidden {
  display: none;
}
