@import '../../theme/colours';

.root {
    display: flex;
    align-items: center;
    flex-shrink: 0;
    width: 100%;
    box-sizing: border-box; 
    z-index: 1100;
    position: fixed;
    top: 0;
    left: auto;
    right: 0;
    background-color: $color-White;
    color: $color-Primary;
    padding-left: 16px;
    padding-right: 16px;
    min-height: 56px; 
    @media print {
        position: absolute;
    };
    @media (min-width: 0px) and (oriantation: landscape) {
        min-height: 48px; 
    };
    @media (min-width: 600px) {
        padding-left: 24px;
        padding-right: 24px;   
        min-height: 72px; 
    };
};

.title { flex-grow: 1; display: flex; };

.actionsContainer {
    flex-grow: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.tabsContainer {
    flex-grow: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
