@import '../../theme/colours';
@import '../../theme/fonts';

.mainWrapper {
  display: flex;
}

.columnLeft {
  width: 40%;
  font-family: $font-family-source-sans-pro;
}

.rowRight {
  font-family: $font-family-source-sans-pro;
  height: 15vh;
  justify-content: center;
  align-items: center;

  .soundWaveWrapper {
    margin-top: 40px;
  }
}

.columnRight {
  margin: 60px 0px;
  height: 86vh;
  width: 60%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.messageContainer {
  background-color: $color-White;
  height: 82vh;
  width: 35vw;
  margin: 20px 20px;
  padding: 20px 20px;
  display: flex;
  flex-direction: column;
  position: relative;
  border-radius: 6px;
  color: rgba(0, 0, 0, 0.87);
  justify-content: center;
  align-items: center;
  overflow-wrap: anywhere;
}

.errorNumber {
  font-size: 200px;
  color: $color-NGLight2;
  font-weight: bold;
  line-height: 0.28;
}

.title {
  font-size: 22px;
  margin-bottom: 15px;
  text-align: center;
}

.upperContentText {
  font-size: 18px;
  margin-bottom: 20px;
  text-align: center;
}

.lowerContentText {
  font-size: 18px;
  margin-bottom: 30px;
  text-align: center;
}

@media screen and (max-width: 800px) {
  .mainWrapper { 
    flex-direction: column-reverse;
    margin-top: 80px;
  }
  
  .columnRight {
    width: 100%;
    height: 10vh;
    justify-content: center;
    align-items: center;
  }

  .columnLeft {
    width: 100%;
  }

  .messageContainer {
    width: 80%;
  }

  .soundWaveWrapper {
    width: 100%;
  }
}

