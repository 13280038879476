@import '../../theme/colours';

.container {
  width: 25%;
  position: fixed;
  z-index: 1;
  right: 0;
  bottom:0;
  top: 0;
  background-color: white;
  transition: width 0.6s;
  display: flex;
  flex-direction: column;
}

.content {
  min-height: 150px;
  flex: 1 1 auto;
  padding: 8px 24px;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

.title {
  margin: 0;
  padding: 12px 16px;
  background-color: white;
  flex: 0 0 auto;
}

.closeButton {
  position: absolute;
  right: 10px;
  top: 7px;
  color: $color-NGDark2;
}
