@import '../../theme/colours';

.messageStyle {
  height: auto;
  color: $color-NGDark1;
  background-color: $color-White;
  display: flex;
  flex-direction: column;
  padding: 0;
  box-sizing: border-box;
  position: relative;

  &.activeMessage {
    background-color: $color-NGLight3;
  }

  &.readMessage {
    color: $color-NG-NeutralsGrey;
  }

  &:hover {
    background-color: $color-NGLight3;
    cursor: pointer;
  }
}

.messageIcon {
  position: absolute;
  left: 0;
  top: 3px;

  & svg {
    display: block;
    width: 10px;
    height: 10px;
  }
}

.messageTitle {
  position: relative;
  padding-right: 40px;
  padding-left: 24px;
  border: solid 0.1px transparent;
  margin: 12px 10px 12px 10px;

  &:focus {
    outline: solid 2px orange;
    outline-offset: 8px;
  }
}

.messageTime {
  position: absolute;
  right: 0;
  color: $color-NG-NeutralsGrey;
}

.messageContent {
  display: none;
  padding: 0 10px 12px 34px;

  &.activeContent {
    display: block;
  }
}

.messageLink {
  margin-top: 12px;
}
