@import './../../theme/colours';

$dropdown-height: calc(85vh - 70px);
$title-height: 50px;

.messagesDropDown {
  position: absolute;
  right: 0;
  top: 100%;
  border: solid 1px $color-NGLight2;
  background-color: $color-White;
  border-radius: 6px;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.05);
  max-height: $dropdown-height;
  margin-top: 13px;
  width: 312px;
  overflow-y: auto;
  overflow-x: hidden;
  box-sizing: content-box;
  padding: 0;
  z-index: 1;
}

.messageButton {
  color: $color-NGDark2;

  &::after {
    height: 16px;
    content: attr(data-count);
    margin: 0 0 6px 12px;
    padding: 0 4px;
    border-radius: 6px;
    color: $color-White;
    border: solid 2px $color-White;
    background-color: $color-Error;
    font-size: 12px;
    font-weight: 700;
    font-family: "SourceSansPro";
    position: absolute;
    top: 5px;
    left: 10px;
    opacity: 0;
    transform: scale(0.5);
    transition: transform, opacity;
    transition-duration: 0.3s;
    transition-timing-function: ease-out;
  }

  &.show::after {
    transform: scale(1);
    opacity: 1;
  }

  &:active,
  &.activeMessagesButton {
    background: transparent;
    color: $color-NGDark2;
  }

  &:hover {
    color: $color-NGDark2;
  }
}

.messagesListTitle {
  color: $color-NG-NeutralsGrey;
  width: 100%;
  height: $title-height;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 16px;

  &:focus {
    outline: none;
  }
}

.border{
  border: none;
  border-top: 1px solid $color-NGLight2;
  margin: 0;
  width: 100%;

  &:focus {
    outline: none;
  }
}

.bellIcon {
  height: 18px;
  width: 18px;
  margin-right: 4px;
}

.messagesWrapper {
  overflow-y: auto;
  height: calc(#{$dropdown-height} - #{$title-height} - 5px);
  overflow-x: hidden;
  min-height: 200px;

  &:focus {
    outline: none;
  }
}

.closeButton {
  position: absolute;
  right: 4px;
  top: 4px;
  color: $color-NGDark2;

  &[data-item="menuitem"] {
    width: auto;
  }

  &[data-item="menuitem"]:focus {
    background: $color-White;
    outline: solid 2px orange;
  }
}

.emptyState {
  display: flex;
  justify-content: center;
  align-items: center;
}

.stateMessage {
  color: $color-NGDark1;
}

.hidden {
  display: none;
}
