@import '../../theme/sizes';

.mainWrapper {
  display: flex;
  flex-direction: column;
  background-color: #FAFAFA;
}

.businessDropDownLabel {
  margin-top: 40px;
  margin-bottom: 16px;
}

.businessContinueButton {
  margin-top: 16px;
  width: fit-content;
}

.doneIcon {
  font-size: $size-medium;
  position: absolute;
  right: 20px;
}

.menuItem {
  font-weight: normal;
}