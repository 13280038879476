@import '../../theme/colours';
@import "../../theme/sizes";


.tableHeaderButton {
  font-size: inherit;
  padding: 0;
  margin: 0;
  border: 0;
  color: inherit;

  &.active svg,
  &.active {
    color: $color-PrimaryDark1;
  }
  &:not(.active):hover {
    color: $color-NG-NeutralsGrey;
  }
}

.sortArrowIcon {
  color: $color-NGLight1;
  font-size: $size-small;
}
