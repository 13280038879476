@import '../../theme/colours';

.root {
  background-color: $color-NGLight3;
  min-height: 100%;
  box-sizing: border-box;
  padding-top: 80px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.content {
  flex: 1 1 auto;
  padding: 0 24px 0;
  display: flex;
  flex-direction: column;
}

.header {
  padding: 0 24px 0;
  flex: 0 1 auto;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
}

.centeredTitle {
  justify-content: center;
}
