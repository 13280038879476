@import '../../theme/colours';

.root {
  flex: 1 1 auto;
  margin-bottom: 24px;
  border-radius: 6px;
  background-color: $color-White;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
